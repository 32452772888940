import type { CSSProperties } from 'react';

export function Logo(props: { inverted?: boolean; style?: CSSProperties }) {
  return (
    <img
      alt="Lyra"
      src="/assets/img/logo.png"
      style={{
        marginLeft: 6,
        filter: props.inverted ? 'invert(0%)' : 'invert(100%)',
        ...(props.style ?? {}),
      }}
      width={50}
    />
  );
}
