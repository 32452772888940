import { Box, List } from '@mui/material';

import { NuovaIpotecaFormFields } from './nuova-ipoteca';

export function IpotecheTab(props: { ipoteche: any}) {
  return (
    <Box sx={{ maxHeight: '300px' }}>
      <List sx={{ maxHeight: '300px', overflowY: 'auto' }} dense disablePadding>
        <Box pt={2} sx={{ minWidth: '1300px', overflowX: 'auto' }}>
          {props.ipoteche.map((_ipoteca: any, ipotecaIndex: number) => (
            <NuovaIpotecaFormFields ipoteca_index={ipotecaIndex} />
          ))}
        </Box>
      </List>
    </Box>
  );
}
