import CheckIcon from '@mui/icons-material/Check';
import { Box, ListItem, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../../../../../../form/fields/checkbox-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function NuovoSoggettoFormFields(props: { soggetto_index: number }) {
  const { getValues } = useFormContext<any>();
  const soggetto = getValues(`data.soggetti_obj.${props.soggetto_index}`);

  return (
    <ListItem key={`lotto_${props.soggetto_index}`} sx={{ paddingLeft: 0, paddingRight: '10px' }}>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        {soggetto.soggetto_esistente === 0 && (
        <CheckboxField
          defaultValue="true"
          label=""
          name={`data.soggetti_obj.${props.soggetto_index}.crea`}
          sx={{ width: '100px' }}
        />
        )}
        {soggetto.soggetto_esistente === 1 && (
        <Tooltip
          title="Esistente"
          followCursor
        >
          <CheckIcon color="success" sx={{ marginTop: '10px' }} />
        </Tooltip>
        )}
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="Nome"
          name={`data.soggetti_obj.${props.soggetto_index}.nome`}
          size="small"
          required
        />
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="Codice Fiscale"
          name={`data.soggetti_obj.${props.soggetto_index}.codicefiscale`}
          size="small"
        />
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="P.Iva"
          name={`data.soggetti_obj.${props.soggetto_index}.partitaiva`}
          size="small"
        />
      </Box>
    </ListItem>
  );
}
