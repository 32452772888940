import { Box, Button, Divider, List, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { useMutations } from '../../../../../../../api/hooks/useMutations';
import type { Lotto } from '../../../../../../../types/fascicolo-file';
import { Form } from '../../../../../form';

import { NuovoLottoFormFields } from './nuovo-lotto';

export function AvvisoVenditaContent(props: { lotti: Lotto[], fileId: number; }) {
  const { fascicolo } = useParams();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`, props.fileId],
      type: 'all',
      refetchType: 'active',
    });
  }, [fascicolo, props.fileId, queryClient]);

  const { create, isLoading } = useMutations({
    key: `fascicolo/${fascicolo}/asset`,
    onSuccess: handleSuccess,
  });

  const handleCreate = useCallback(
    (values: any) => {
      const filteredNuoviLotti = values.lotti
        .map((elem: any) => ({
          ...elem,
          asset: elem.asset.filter((s: any) => s.crea && s.asset_esistente === 0),
        }));

      create({
        data: {
          lotto_obj: filteredNuoviLotti,
          smart: 1,
        },
      });
    },
    [create],
  );

  const renderButtonNew = useMemo(() => {
    const filteredNuoviLotti = props.lotti
      .map((elem: any) => elem.asset.filter((s: any) => s.asset_esistente === 0));

    const emptyNuoviLotti = filteredNuoviLotti.filter((subArray: any) => subArray.length > 0);

    if (emptyNuoviLotti.length <= 0) return null;

    return (
      <Box display="flex" justifyContent="end" sx={{ p: 1 }}>
        <Button disabled={isLoading} type="submit">
          Crea nuovi Asset
        </Button>
      </Box>
    );
  }, [isLoading, props.lotti]);

  return (
    <Form
      defaultValues={{ lotti: props.lotti }}
      onSubmit={handleCreate}
    >
      <Typography sx={{ paddingTop: '5px' }}><b>Gestione Lotti</b></Typography>
      <Box sx={{ maxHeight: '300px' }}>
        <List sx={{ maxHeight: '300px', overflowY: 'auto' }} dense disablePadding>
          {props.lotti.map((lotto, lottoIndex) => (
            <Box pt={2}>
              <Typography sx={{ background: '#f1f1f1', padding: '5px', position: 'sticky', top: 0, zIndex: 3 }}>
                <b>Lotto numero: {lotto.numero}</b>
              </Typography>
              <Box pt={2}>
                {lotto.asset.map((asset, assetIndex) => (
                  <NuovoLottoFormFields asset_index={assetIndex} lotto_index={lottoIndex} />
                ))}
              </Box>
              <Divider sx={{ paddingTop: '10px' }} />
            </Box>
          ))}
        </List>
      </Box>
      <Divider />
      {renderButtonNew}
    </Form>
  );
}
