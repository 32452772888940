import { Alert, Divider, Paper, Typography } from '@mui/material';

import type { FileElaborato } from '../../../../../../../types/fascicolo-file';

import { NuoviPrecettiForm } from './nuovi-precetti-form';

export function NuoviPrecetti(props: { file: FileElaborato; fileId: number; isSfavore: boolean }) {
  if (!props.file.soggetti_nuovi?.length) return null;

  return (
    <Paper sx={{ mt: 2 }} variant="outlined">
      <Typography sx={{ p: 2 }} variant="h5">
        Nuovi soggetti
      </Typography>
      <Divider />
      <Alert severity="warning" sx={{ p: 2, m: 2, mb: 1 }}>
        Sono stati trovati nuovi soggetti all'interno del titolo, per poter
        procedere con il processamento è necessario creare i soggetti collegati
        al titolo.
      </Alert>
      <NuoviPrecettiForm file={props.file} fileId={props.fileId} isSfavore={props.isSfavore} />
    </Paper>
  );
}
