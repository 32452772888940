import { Box, Button, Divider, List } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { useMutations } from '../../../../../../../../api/hooks/useMutations';
import type {
  FileElaborato,
  FileSoggetto,
} from '../../../../../../../../types/fascicolo-file';
import { Form } from '../../../../../../form';

import { NuovoPrecettoFormFields } from './nuovo-precetti-form-fields';

export type NuoviSoggettiFormValue = {
  soggetti: Array<FileSoggetto & { excluded?: boolean }>;
};

export function NuoviPrecettiForm(props: {
  file: FileElaborato;
  fileId: number;
  isSfavore: boolean
}) {
  const { fascicolo } = useParams();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`, props.fileId],
      type: 'all',
      refetchType: 'active',
    });
  }, [fascicolo, props.fileId, queryClient]);

  const { create, isLoading } = useMutations({
    key: `fascicolo/${fascicolo}/soggetto`,
    onSuccess: handleSuccess,
  });

  const handleCreate = useCallback(
    (values: NuoviSoggettiFormValue) => {
      const filteredNuoviSoggetti = values.soggetti
        .filter(s => !s.excluded)
        .map(soggetto => {
          const { excluded, ...rest } = soggetto;

          return rest;
        });

      create({
        data: {
          soggetti_nuovi: filteredNuoviSoggetti,
          smart: 1,
        },
      });
    },
    [create],
  );

  const renderButtonNew = useMemo(() => {
    const emptyNuoviSoggetti = props.file.soggetti_nuovi?.filter((s: any) => s.soggetto_esistente === 0);

    if (emptyNuoviSoggetti && emptyNuoviSoggetti.length <= 0) return null;

    return (
      <Box display="flex" justifyContent="end" sx={{ p: 1 }}>
        <Button disabled={isLoading} type="submit">
          Crea nuovi soggetti
        </Button>
      </Box>
    );
  }, [isLoading, props.file.soggetti_nuovi]);

  return (
    <Form
      defaultValues={{ soggetti: props.file.soggetti_nuovi }}
      onSubmit={handleCreate}
    >
      <List dense disablePadding>
        {props.file.soggetti_nuovi?.map((soggetto, index) => (
          <NuovoPrecettoFormFields key={soggetto.nome} index={index} isSfavore={props.isSfavore} />
        ))}
      </List>
      <Divider />
      {renderButtonNew}
    </Form>
  );
}
