import { Box, Divider, List, Typography } from '@mui/material';

import type { Lotto } from '../../../../../../../../types/fascicolo-file';

import { NuovoLottoFormFields } from './nuovo-lotto';

export function LottoTab(props: { lotti: Lotto[]}) {
  return (
    <Box sx={{ maxHeight: '300px' }}>
      <List sx={{ maxHeight: '300px', overflowY: 'auto' }} dense disablePadding>
        {props.lotti.map((lotto, lottoIndex) => (
          <Box pt={2} sx={{ minWidth: '1300px', overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', background: '#f1f1f1', padding: '5px 10px', position: 'sticky', top: 0, zIndex: 3 }}>
              <Typography><b>Lotto numero: {lotto.numero}</b></Typography>
            </Box>
            <Box pt={2}>
              {lotto.asset.map((asset, assetIndex) => (
                <NuovoLottoFormFields asset_index={assetIndex} lotto_index={lottoIndex} />
              ))}
            </Box>
            <Divider sx={{ paddingTop: '10px' }} />
          </Box>
        ))}
      </List>
    </Box>
  );
}
