// import type { Theme } from '@mui/material';

// export function authenticateCSS(theme: Theme) {
//   return {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     width: '100%',
//     background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
//   };
// }

export function authenticateCSS() {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url("/assets/img/bg.avif")',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    zIndex: -1,
  };
}
