import type { CSSProperties } from 'react';

export function LogoFull(props: { inverted?: boolean; style?: CSSProperties }) {
  return (
    <img
      alt="Lyra"
      src="/assets/img/logofull.png"
      style={{
        marginLeft: 8,
        filter: props.inverted ? 'invert(0%)' : 'invert(100%)',
        ...(props.style ?? {}),
      }}
      width={120}
    />
  );
}
