import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Card } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useMutations } from '../../../api/hooks/useMutations';
import { Form } from '../../../common/components/form';
import { PasswordField } from '../../../common/components/form/fields/password-field';
import { LogoFull } from '../../../common/components/logofull';
import { useInputValidation } from '../../../configurations/validations';

import { schema } from './schema';

export function RecuperaPassword() {
  const { token } = useParams();
  const { update, isLoading, isError, isSuccess } = useMutations({
    key: `recuperapassword/${token}`,
    unique: true,
    keepAlive: true,
  });

  const handleResetPassword = useCallback(
    (values: { new_password: string; ri_password: string }) => {
      update({ data: { ...values } });
    },
    [update],
  );
  const validations = useInputValidation();

  return (
    <Card sx={{ padding: '12px 32px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
      <Form
        options={{
          mode: 'onChange',
          resolver: yupResolver(schema(validations)),
        }}
        onSubmit={handleResetPassword}
      >
        <ResetPasswordForm
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </Form>
    </Card>
  );
}

function ResetPasswordForm(props: {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}) {
  const navigate = useNavigate();
  const {
    formState: { isValid },
  } = useFormContext();

  const handleGoToLogin = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Box display="flex" flexDirection="column" gap={2} width={350}>
      <LogoFull style={{ margin: '0 auto' }} inverted />
      <Typography align="center" fontWeight={900} variant="h2">
        Reimposta password
      </Typography>
      <Typography align="center" variant="body2">
        Inserisci una nuova password
      </Typography>
      <Divider sx={{ my: 2 }} />
      <PasswordField label="Password" name="password" defaultEnabled required />
      <PasswordField
        label="Ripeti Password"
        name="ri_password"
        defaultEnabled
        required
      />
      <Divider sx={{ my: 2 }} />
      <Button
        disabled={!isValid || props.isLoading}
        type="submit"
        variant="outlined"
      >
        Reimposta password
      </Button>
      <Button
        disabled={props.isLoading}
        variant="text"
        onClick={handleGoToLogin}
      >
        Torna al login
      </Button>
      {props.isError && (
        <Alert severity="error">
          Qualcosa è andato storto durante la reimpostazione della password,
          contatta l'amministrazione
        </Alert>
      )}
      {props.isSuccess && (
        <Alert severity="success">
          Password modificata con successo, torna al login per effettuare
          l'accesso
        </Alert>
      )}
    </Box>
  );
}
