import { CheckTwoTone, OpenInNewTwoTone } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { FileElaborato } from '../../../../../../../types/fascicolo-file';
import withConfirmationButton from '../../../../../with-confirmation';

const ButtonWithConfirmation = withConfirmationButton(Button);

export function TitoliProcess(props: { file: FileElaborato; onClose: () => void }) {
  const { fascicolo } = useParams();
  const navigate = useNavigate();

  const handleGoToCreateTitolo = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/garanziareale/add`;

    navigate(path, { state: props.file });
    props.onClose();
  }, [fascicolo, navigate, props]);

  const handleGoToLinkedTitolo = useCallback(() => {
    const path = `/fascicolo/${fascicolo}/garanziareale/${props.file?.link_titolo}`;

    navigate(path);
    props.onClose();
  }, [fascicolo, props, navigate]);

  // If the file has no title the file cannot be proccessed
  if (!Object.values(props.file ?? {}).length) {
    return null;
  }

  if (props.file.link_titolo) {
    return (
      <Button
        color="success"
        startIcon={<OpenInNewTwoTone />}
        variant="contained"
        onClick={handleGoToLinkedTitolo}
      >
        Vai all'ipoteca
      </Button>
    );
  }

  return (
    <ButtonWithConfirmation
      buttonProps={{
        color: 'success',
        variant: 'contained',
        startIcon: <CheckTwoTone />,
      }}
      message="Vuoi procedere alla creazione dell'ipoteca?"
      onConfirm={handleGoToCreateTitolo}
    >
      Processa
    </ButtonWithConfirmation>
  );
}
