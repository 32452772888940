import CheckIcon from '@mui/icons-material/Check';
import { Box, ListItem, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import type { NuoviSoggettiFormValue } from '..';
import { CheckboxField } from '../../../../../../../form/fields/checkbox-field';
import { CurrencyField } from '../../../../../../../form/fields/currency-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function NuovoSoggettoFormFields(props: { index: number }) {
  const { getValues } = useFormContext<NuoviSoggettiFormValue>();
  const soggetto = getValues(`soggetti.${props.index}`);

  return (
    <ListItem>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        {soggetto.soggetto_esistente === 0 && (
        <CheckboxField
          defaultValue="true"
          label=""
          name={`soggetti.${props.index}.crea`}
          sx={{ width: '100px' }}
        />
        )}
        {soggetto.soggetto_esistente === 1 && (
        <Tooltip
          title="Esistente"
          followCursor
        >
          <CheckIcon color="success" sx={{ marginTop: '10px' }} />
        </Tooltip>
        )}
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="Nome"
          name={`soggetti.${props.index}.nome`}
          size="small"
        />
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="P. IVA"
          name={`soggetti.${props.index}.partitaiva`}
          size="small"
          validationKey={soggetto.soggetto_esistente === 0 ? 'partitaiva' : ''}
        />
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="CF"
          name={`soggetti.${props.index}.codicefiscale`}
          size="small"
          validationKey={soggetto.soggetto_esistente === 0 ? 'codicefiscale' : ''}
        />
        <CurrencyField
          disabled={soggetto.soggetto_esistente === 1}
          label="Importo"
          name={`soggetti.${props.index}.importo`}
          size="small"
        />
      </Box>
    </ListItem>
  );
}
