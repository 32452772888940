import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import type { SyntheticEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMutations } from '../../../../../../../api/hooks/useMutations';
import type { FileElaborato } from '../../../../../../../types/fascicolo-file';
import { Form } from '../../../../../form';

import { AssetTab } from './asset-tab';
import { IpotecheTab } from './ipoteche-tab';
import { SoggettiTab } from './soggetti-tab';

export function CertNotarileContent(props: { data: FileElaborato, fileId: number; }) {
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { fascicolo } = useParams();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`, props.fileId],
      type: 'all',
      refetchType: 'active',
    });
  }, [fascicolo, props.fileId, queryClient]);

  const { create, isLoading } = useMutations({
    key: `fascicolo/${fascicolo}/certnotarile`,
    onSuccess: handleSuccess,
  });

  const handleCreate = useCallback((values: any) => {
    const filteredNuoviAsset = values.data.asset_obj
      .filter((s: any) => s.crea && s.asset_esistente === 0);

    const filteredNuoviSoggetti = values.data.soggetti_obj
      .filter((s: any) => s.crea && s.soggetto_esistente === 0);

    const filteredNuoveIpoteche = values.data.ipoteche_obj
      .filter((s: any) => s.crea && s.ipoteca_esistente === 0);

    create({
      data: {
        asset_obj: filteredNuoviAsset,
        soggetti_obj: filteredNuoviSoggetti,
        ipoteche_obj: filteredNuoveIpoteche,
        smart: 1,
      },
    });
  },
  [create],
  );

  const renderButtonNew = useMemo(() => {
    const filteredNuoviAsset = props.data.asset_obj
      .filter((s: any) => s.asset_esistente === 0);

    const filteredNuoviSoggetti = props.data.soggetti_obj
      .filter((s: any) => s.soggetto_esistente === 0);

    const filteredNuoveIpoteche = props.data.ipoteche_obj
      .filter((s: any) => s.ipoteca_esistente === 0);

    if (filteredNuoviAsset.length <= 0 && filteredNuoviSoggetti.length <= 0 && filteredNuoveIpoteche.length <= 0) return null;

    return (
      <Box display="flex" justifyContent="end" sx={{ p: 1 }}>
        <Button disabled={isLoading} type="submit">
          Crea Entità
        </Button>
      </Box>
    );
  }, [isLoading, props.data.asset_obj, props.data.ipoteche_obj, props.data.soggetti_obj]);

  return (
    <Form
      defaultValues={{ data: props.data }}
      onSubmit={handleCreate}
    >
      { props.data.asset_obj.length > 0 && (
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ height: '0px', minHeight: '46px!important', paddingLeft: 0 }}>
          <Typography sx={{ minHeight: '0px' }} variant="h5">
            Asset
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AssetTab lotti={props.data.asset_obj} />
        </AccordionDetails>
      </Accordion>
      )}
      { props.data.soggetti_obj.length > 0 && (
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ height: '0px', minHeight: '46px!important', paddingLeft: 0 }}>
          <Typography sx={{ minHeight: '0px' }} variant="h5">
            Soggetti Debitori
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SoggettiTab soggetti={props.data.soggetti_obj} />
        </AccordionDetails>
      </Accordion>
      )}
      { props.data.ipoteche_obj.length > 0 && (
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMore />} sx={{ height: '0px', minHeight: '46px!important', paddingLeft: 0 }}>
          <Typography sx={{ minHeight: '0px' }} variant="h5">
            Ipoteche
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IpotecheTab ipoteche={props.data.ipoteche_obj} />
        </AccordionDetails>
      </Accordion>
      )}
      {renderButtonNew}
    </Form>
  );
}
