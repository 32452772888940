import type { QueryFunctionContext } from '@tanstack/react-query';

import { instance } from '../axios';
import type { ManyQueryKey } from '../types/params';

type Response<R> = {
  data: {
    risultati: R[];
    totale?: number;
    pagina?: number;
  };
};

export async function getMany<R, F>({
  queryKey: [key, params],
}: QueryFunctionContext<ManyQueryKey<F>>) {
  const {
    data: { risultati, totale },
  } = await instance.get<R, Response<R>>(`${key}/`, {
    params,
  });

  const letta: any = params;
  if (key === 'notifica' && letta?.letta === 0)localStorage.setItem('notificheTot', totale?.toString() || '0');

  return risultati;
}
