import { PreviewTwoTone } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import useToggleState from '../../../../hooks/useToggleState';

import type { PreviewDialogProps } from './dialog';
import { PreviewDialog } from './dialog';

export function Preview(
  props: Pick<PreviewDialogProps, 'link' | 'disablePortal' | 'fileid'>,
) {
  const [open, , toggleOn, toggleOff] = useToggleState(false);

  if (props.link === null || props.link === undefined || props.link === '') return null;

  return (
    <>
      {open && (
        <PreviewDialog
          disablePortal={props.disablePortal}
          fileid={props.fileid || null}
          link={props.link}
          open={open}
          onClose={toggleOff}
        />
      )}
      <Tooltip placement="top" title="Anteprima" followCursor>
        <IconButton onClick={toggleOn}>
          <PreviewTwoTone color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );
}
