import { Box, List } from '@mui/material';

import { NuovoSoggettoFormFields } from './nuovo-soggetto';

export function SoggettiTab(props: { soggetti: any}) {
  return (
    <Box sx={{ maxHeight: '300px' }}>
      <List sx={{ maxHeight: '300px', overflowY: 'auto' }} dense disablePadding>
        <Box pt={2} sx={{ minWidth: '1000px', overflowX: 'auto' }}>
          {props.soggetti.map((_soggetto: any, soggettoIndex: number) => (
            <NuovoSoggettoFormFields soggetto_index={soggettoIndex} />
          ))}
        </Box>
      </List>
    </Box>
  );
}
