import CheckIcon from '@mui/icons-material/Check';
import { Box, ListItem, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../../../../../../form/fields/checkbox-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function NuovoSoggettoFormFields(props: { soggetto_index: number }) {
  const { getValues } = useFormContext<any>();
  const soggetto = getValues(`data.soggetti_obj.${props.soggetto_index}`);

  return (
    <ListItem key={`lotto_${props.soggetto_index}`} sx={{ paddingLeft: 0, paddingRight: '10px' }}>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        <Box display="flex" width="60px">
          {soggetto.soggetto_esistente === 0 && (
            <CheckboxField
              defaultValue="true"
              label=""
              name={`data.soggetti_obj.${props.soggetto_index}.crea`}
            />
          )}
          {soggetto.soggetto_esistente === 1 && (
            <Tooltip
              title="Esistente"
              followCursor
            >
              <CheckIcon color="success" sx={{ marginTop: '10px' }} />
            </Tooltip>
          )}
        </Box>
        <TextField
          label="Tipologia"
          name={`data.soggetti_obj.${props.soggetto_index}.tipologia`}
          size="small"
          sx={{ width: '300px' }}
          disabled
        />
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="Nome"
          name={`data.soggetti_obj.${props.soggetto_index}.nome`}
          size="small"
          sx={{ width: '500px' }}
          required
        />
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="Codice Fiscale"
          name={`data.soggetti_obj.${props.soggetto_index}.codicefiscale`}
          size="small"
          sx={{ width: '300px' }}
        />
        <TextField
          disabled={soggetto.soggetto_esistente === 1}
          label="P.Iva"
          name={`data.soggetti_obj.${props.soggetto_index}.partitaiva`}
          size="small"
          sx={{ width: '300px' }}
        />
      </Box>
    </ListItem>
  );
}
