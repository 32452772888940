import { Box } from '@mui/material';
import type MuiTextField from '@mui/material/TextField';
import type { ChangeEvent, ComponentProps } from 'react';
import React, { forwardRef } from 'react';

import {
  BoxWrapper,
  ErrorMessage,
  Fieldset,
  Input,
  Label,
  Legend,
} from './styles';

type FakeChangeEvent = {
  target: {
    type: string;
    name?: string;
    id?: string;
    value?: number | string;
  };
};

function CurrencyInput2(props: ComponentProps<typeof MuiTextField>, ref: any) {
  const fakeChangeEvent: FakeChangeEvent = {
    target: {
      type: 'number',
      name: props.name,
      id: props.id,
    },
  };

  return (
    // @ts-ignore
    <Box>
      <BoxWrapper>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <Input
          ref={ref}
          decimalScale={2}
          disabled={props.disabled}
          intlConfig={{
            locale: 'it-IT',
            currency: 'EUR',
          }}
          max={Number.MAX_SAFE_INTEGER}
          placeholder="0,00"
          style={props.size === 'small' ? { padding: '8.5px 10px', width: '8vw' } : {}}
          value={
            props.value && typeof props.value === 'number'
              ? props.value
              : undefined
          }
          onBlur={event => {
            fakeChangeEvent.target.value = parseLocaleNumber(
              event.target.value ?? '0',
              'it-IT',
            );
            props?.onChange?.(
              fakeChangeEvent as unknown as ChangeEvent<
                HTMLTextAreaElement | HTMLInputElement
              >,
            );
          }}
          onValueChange={val => {
            fakeChangeEvent.target.value = parseLocaleNumber(
              val ?? '',
              'it-IT',
            );
            props?.onChange?.(
              fakeChangeEvent as unknown as ChangeEvent<
                HTMLTextAreaElement | HTMLInputElement
              >,
            );
          }}
        />
        <Label className={props.size === 'small' ? 'labelFix' : ''} data-shrink="true" htmlFor=":rh:" id=":rh:-label">
          {props.label} {props.required ? '*' : ''}
        </Label>
        <Fieldset>
          <Legend>{props.label}</Legend>
        </Fieldset>
      </BoxWrapper>
      {props.helperText && <ErrorMessage>{props.helperText}</ErrorMessage>}
    </Box>
  );
}

function parseLocaleNumber(stringNumber: string, locale: string): number | string {
  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  if (stringNumber.endsWith(decimalSeparator)) return Number(stringNumber);
  if (stringNumber.endsWith(`${decimalSeparator}0`)) {
    return Number(`${stringNumber}0`);
  }

  const value = parseFloat(
    stringNumber
      .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator}`), '.'),
  );

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return '';

  return value;
}

export default forwardRef(CurrencyInput2);
