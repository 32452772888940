import { Alert, Button, Card } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMutations } from '../../../api/hooks/useMutations';
import { Form } from '../../../common/components/form';
import { TextField } from '../../../common/components/form/fields/text-field';
import { LogoFull } from '../../../common/components/logofull';

export function RequestUsername() {
  const { mutate, isLoading, isError, isSuccess } = useMutations({
    key: 'recuperausername',
    unique: true,
    keepAlive: true,
    successMessage: 'Email inviata con successo',
  });

  const handleRequestPassword = useCallback(
    (values: { email: string }) => {
      mutate({ data: values });
    },
    [mutate],
  );

  return (
    <Card sx={{ padding: '12px 32px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
      <Form options={{ mode: 'onChange' }} onSubmit={handleRequestPassword}>
        <RequestUserNamedForm
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </Form>
    </Card>
  );
}

function RequestUserNamedForm(props: {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}) {
  const navigate = useNavigate();
  const {
    formState: { isValid },
  } = useFormContext();

  const handleGoToLogin = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Box display="flex" flexDirection="column" gap={2} width={350}>
      <LogoFull style={{ margin: '0 auto' }} inverted />
      <Typography align="center" fontWeight={900} variant="h2">
        Recupera username
      </Typography>
      <Typography align="center" variant="body2">
        Riceverai una mail con un link alla pagina di reset della username
      </Typography>
      <Divider sx={{ my: 2 }} />
      {!props.isSuccess && (
        <>
          <TextField label="Email" name="email" required />
          <Divider sx={{ my: 2 }} />
          <Button
            disabled={!isValid || props.isLoading}
            type="submit"
            variant="outlined"
          >
            Recupera username
          </Button>
        </>
      )}
      <Button
        disabled={props.isLoading}
        variant="text"
        onClick={handleGoToLogin}
      >
        Torna al login
      </Button>
      {props.isError && (
        <Alert severity="error">
          Qualcosa è andato storto durante il recupero della username, contatta
          l'amministrazione
        </Alert>
      )}
      {props.isSuccess && (
        <Alert severity="success">
          Controlla la tua casella di posta per il link di reset della username
        </Alert>
      )}
    </Box>
  );
}
