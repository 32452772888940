/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/iframe-has-title */
import { CloseTwoTone } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import useToggleState from '../../hooks/useToggleState';

import styles from './styles.module.css';

export function ChatAI() {
  const token = localStorage.getItem('token') ?? '';
  const client = localStorage.getItem('selectedClient');
  const isAdmin = localStorage.getItem('isAdmin');
  const { W, H } = JSON.parse(localStorage.getItem('Siria') || '{}');
  const sidebarRef = useRef<any>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(W || 400);
  const [sidebarHeight, setSidebarHeight] = useState(H || 500);
  const [open, toggle] = useToggleState(false);
  const handleOpen = useCallback(() => {
    toggle();
  }, [toggle]);
  const location = useLocation();
  const locationArray = location.pathname.split('/');
  const idFascicolo = parseInt(locationArray[2]);

  useEffect(() => {
    const handleResize = () => {
      const wWidth = window.innerWidth - 8;
      const wHeight = window.innerHeight;
      setIsResizing(false);
      if (sidebarRef.current && sidebarRef.current.offsetWidth >= wWidth && sidebarWidth > 350) {
        setSidebarWidth(wWidth);
        setIsResizing(true);
      }
      if (sidebarRef.current && sidebarRef.current.offsetHeight >= wHeight && sidebarHeight > 350) {
        setSidebarHeight(wHeight);
        setIsResizing(true);
      }
      localStorage.setItem('Siria', `{"W": ${sidebarWidth}, "H": ${sidebarHeight}}`);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sidebarHeight, sidebarWidth]);

  const resize = useCallback(
    (mouseMoveEvent: any) => {
      if (isResizing && sidebarRef.current && mouseMoveEvent.buttons === 1) {
        const wWidth = window.innerWidth - 10;
        const wHeight = window.innerHeight;
        const resizeWidth = 40 + window.innerWidth - mouseMoveEvent.clientX;
        const resizeHeight = 25 + window.innerHeight - mouseMoveEvent.clientY;
        if (resizeWidth >= 350 && resizeWidth <= wWidth) {
          setSidebarWidth(resizeWidth);
        } else {
          if (sidebarWidth < 350)setSidebarWidth(350);
          if (resizeWidth > wWidth)setSidebarWidth(wWidth);
        }
        if (resizeHeight >= 350 && resizeHeight <= wHeight) {
          setSidebarHeight(resizeHeight);
        } else {
          if (sidebarHeight < 350)setSidebarHeight(350);
          if (resizeHeight > wHeight)setSidebarHeight(wHeight);
        }
        localStorage.setItem('Siria', `{"W": ${resizeWidth}, "H": ${resizeHeight}}`);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isResizing],
  );

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);

    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  const lockSelect = () => {
    document.body.classList.add('TextNoSelect');
  };

  const unlockSelect = () => {
    document.body.classList.remove('TextNoSelect');
  };

  if (locationArray[1] !== 'fascicolo' || isNaN(idFascicolo) || (isAdmin === 'true' && client === 'null')) {
    if (open) toggle();

    return null;
  }

  return (
    <>
      { !open && (
      <IconButton
        className={styles.IconAI}
        onClick={handleOpen}
      >
        <AutoAwesomeTwoToneIcon sx={{
          color: '#fff',
          width: '0.9em',
          height: '0.9em',
        }}
        />
        <Typography p={0} sx={{ display: 'none' }} variant="h5">SirIA</Typography>
      </IconButton>
      )}
      <Box
        ref={sidebarRef}
        className={`${styles.ChatAI} ${open ? styles.openChat : ''}`}
        style={{ width: `${sidebarWidth}px`, height: `${sidebarHeight}px`, transition: `${isResizing ? 'none' : '0.5s'}`, cursor: `${isResizing ? 'all-scroll' : 'default'}` }}
      >
        <Box className={styles.headerChat}>
          <Box className={styles.chatResize} onMouseDown={startResizing} onMouseEnter={lockSelect} onMouseLeave={unlockSelect}>
            <ArrowBackIosIcon style={{ rotate: '45deg', color: `${isResizing ? '#fff' : '#ffffff80'}` }} />
          </Box>
          <Typography className={styles.TextSiria} p={2} variant="h5">
            <AutoAwesomeTwoToneIcon className={styles.IconSiria} />
            SirIA
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseTwoTone />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ height: '100%' }}>
          <iframe frameBorder="0" height="100%" src={`https://ia.ceore.it/?fascicolo_id=${idFascicolo}&access=${JSON.parse(token)}`} width="100%" />
        </Box>
      </Box>
    </>
  );
}
