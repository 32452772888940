import CheckIcon from '@mui/icons-material/Check';
import { Box, ListItem, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../../../../../form/fields/checkbox-field';
import { CurrencyField } from '../../../../../../form/fields/currency-field';
import { DateField } from '../../../../../../form/fields/date-field';
import { TextField } from '../../../../../../form/fields/text-field';

export function OrdinanzaFormFields(props: { titolo_index: number }) {
  const { getValues } = useFormContext<any>();
  const titoli = getValues(`titoli.${props.titolo_index}`);

  return (
    <ListItem key={`titoli_${props.titolo_index}`} sx={{ paddingLeft: 0, paddingRight: '10px' }}>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        {titoli.titolo_esistente === 0 && (
          <CheckboxField
            defaultValue="true"
            label=""
            name={`titoli.${props.titolo_index}.crea`}
            sx={{ width: '100px' }}
          />
        )}
        {titoli.titolo_esistente === 1 && (
        <Tooltip
          title="Esistente"
          followCursor
        >
          <CheckIcon color="success" sx={{ marginTop: '10px' }} />
        </Tooltip>
        )}
        <TextField
          label="Tipologia"
          name={`titoli.${props.titolo_index}.tipologia`}
          size="small"
          disabled
        />
        <TextField
          disabled={titoli.titolo_esistente === 1}
          label="Tribunale"
          name={`titoli.${props.titolo_index}.tribunale`}
          size="small"
        />
        <DateField
          DatePickerProps={{
            views: ['year'],
            label: 'Anno',
            inputFormat: 'YYYY',
          }}
          name={`titoli.${props.titolo_index}.anno`}
          size="small"
        />
        <TextField
          disabled={titoli.titolo_esistente === 1}
          label="Numero"
          name={`titoli.${props.titolo_index}.numero`}
          size="small"
          required
        />

        <CurrencyField
          disabled={titoli.titolo_esistente === 1}
          label="Importo"
          name={`titoli.${props.titolo_index}.importo`}
          size="small"
        />
        <CurrencyField
          disabled={titoli.titolo_esistente === 1}
          label="Spese"
          name={`titoli.${props.titolo_index}.spese`}
          size="small"
        />
        <CurrencyField
          disabled={titoli.titolo_esistente === 1}
          label="Compensi Liquidati"
          name={`titoli.${props.titolo_index}.compensi_liquidati`}
          size="small"
        />
      </Box>
    </ListItem>
  );
}
