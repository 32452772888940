import CheckIcon from '@mui/icons-material/Check';
import { Box, ListItem, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../../../../../../form/fields/checkbox-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function NuovoLottoFormFields(props: { lotto_index: number, asset_index: number }) {
  const { getValues } = useFormContext<any>();
  const lotto = getValues(`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}`);

  return (
    <ListItem key={`lotto_${props.lotto_index}'_'${props.asset_index}`} sx={{ paddingLeft: 0, paddingRight: '10px' }}>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        {lotto.asset_esistente === 0 && (
        <CheckboxField
          defaultValue="true"
          label=""
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.crea`}
          sx={{ width: '100px' }}
        />
        )}
        {lotto.asset_esistente === 1 && (
        <Tooltip
          title="Esistente"
          followCursor
        >
          <CheckIcon color="success" sx={{ marginTop: '10px' }} />
        </Tooltip>
        )}
        <TextField
          disabled={lotto.asset_esistente === 1}
          label="Comune"
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.comune`}
          size="small"
          required
        />
        <TextField
          disabled={lotto.asset_esistente === 1}
          label="Indirizzo"
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.indirizzo`}
          size="small"
        />
        <TextField
          disabled={lotto.asset_esistente === 1}
          label="Foglio"
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.foglio`}
          size="small"
          sx={{ width: '500px' }}
          required
        />
        <TextField
          disabled={lotto.asset_esistente === 1}
          label="Mappale"
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.mappale`}
          size="small"
          sx={{ width: '500px' }}
          required
        />
        <TextField
          disabled={lotto.asset_esistente === 1}
          label="Subalterno"
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.subalterno`}
          size="small"
          sx={{ width: '500px' }}
        />
        <TextField
          disabled={lotto.asset_esistente === 1}
          label="Natura"
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.natura`}
          size="small"
          sx={{ width: '500px' }}
        />
        <TextField
          disabled={lotto.asset_esistente === 1}
          label="Consistenza"
          name={`data.lotto_obj.${props.lotto_index}.asset.${props.asset_index}.consistenza`}
          size="small"
          sx={{ width: '500px' }}
        />
      </Box>
    </ListItem>
  );
}
