import { Box, Button, Divider, List, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { useMutations } from '../../../../../../../api/hooks/useMutations';
import type { Titoli } from '../../../../../../../types/fascicolo-file';
import { Form } from '../../../../../form';

import { OrdinanzaFormFields } from './nuova-ordinanza';

export function OrdinanzaAssegnazioneContent(props: { titoli: Titoli[], fileId: number; }) {
  const { fascicolo } = useParams();

  const queryClient = useQueryClient();

  const handleSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [`fascicolo/${fascicolo}/file`, props.fileId],
      type: 'all',
      refetchType: 'active',
    });
  }, [fascicolo, props.fileId, queryClient]);

  const { create, isLoading } = useMutations({
    key: `fascicolo/${fascicolo}/ordinanzaassegnazione`,
    onSuccess: handleSuccess,
  });

  const handleCreate = useCallback(
    (values: any) => {
      const filteredNuoviTitoli = values.titoli.filter((s: any) => s.titolo_esistente === 0);

      create({
        data: {
          titoli_obj: filteredNuoviTitoli,
          smart: 1,
        },
      });
    },
    [create],
  );

  const renderButtonNew = useMemo(() => {
    const filteredNuoviTitoli = props.titoli.filter((s: any) => s.titolo_esistente === 0);

    if (filteredNuoviTitoli.length <= 0) return null;

    return (
      <Box display="flex" justifyContent="end" sx={{ p: 1 }}>
        <Button disabled={isLoading} type="submit">
          Crea Ordinanza
        </Button>
      </Box>
    );
  }, [isLoading, props.titoli]);

  return (
    <Form
      defaultValues={{ titoli: props.titoli }}
      onSubmit={handleCreate}
    >
      <Typography sx={{ paddingTop: '10px', paddingBottom: '10px' }}><b>Ordinanza</b></Typography>
      <Box sx={{ maxHeight: '300px' }}>
        <List sx={{ maxHeight: '300px', overflowY: 'auto' }} dense disablePadding>
          {props.titoli.map((_titolo: any, titoloIndex: number) => (
            <OrdinanzaFormFields titolo_index={titoloIndex} />
          ))}
        </List>
      </Box>
      <Divider />
      {renderButtonNew}
    </Form>
  );
}
