import { Card } from '@mui/material';
import type {
  CellClassParams,
  ColDef,
} from 'ag-grid-community/dist/lib/entities/colDef';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import type { ComponentProps } from 'react';
import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { getLoadingPartial } from './col-def-partials/loading-partial';
import { headerValueGetter } from './getters/header-value-getter';
import { GridWrapper } from './styles';

type Props<Data> = ComponentProps<typeof AgGridReact<Data>> & {
  disabled?: boolean;
  loading?: boolean;
  unboxed?: boolean;
  totale?: Number;
};

const defaultColDef: ColDef = {
  minWidth: 100,
  resizable: true,
  sortable: true,
  lockPinned: true,
  suppressNavigable: true,
  lockPosition: 'left',
  flex: 1,
  headerValueGetter,
};

export function DataGrid<Data = Record<string, any>>(props: Props<Data>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const context = useFormContext();
  const errors = context?.formState?.errors;
  const columns = useMemo(
    () =>
      props.columnDefs?.map(
        (col: ColDef): ColDef => ({
          cellClass: (params: CellClassParams) => {
            const name = params?.colDef?.cellRendererParams?.name;
            const field = params?.colDef?.field;
            const rowIndex = params?.rowIndex;
            const error = _.get(errors, `${name}.${rowIndex}.${field}`);

            return error ? 'ag-invalid' : '';
          },
          ...col,
          editable: props.disabled ? false : col.editable,
          ...(props.loading ? getLoadingPartial() : {}),
        }),
      ) ?? [],
    [errors, props.columnDefs, props.disabled, props.loading],
  );

  const offset = ref.current?.offsetTop ? ref.current.offsetTop + 20 : 200;

  const renderContent = useMemo(
    () => (
      <GridWrapper
        ref={ref}
        height={
          props.rowModelType === 'infinite'
            ? `calc(100vh - ${offset}px)`
            : undefined
        }
      >
        <AgGridReact
          className="ag-theme-alpine"
          defaultColDef={defaultColDef}
          domLayout={
            props.rowModelType === 'infinite' ? 'normal' : 'autoHeight'
          }
          tooltipShowDelay={0}
          enableCellTextSelection
          stopEditingWhenCellsLoseFocus
          suppressDragLeaveHidesColumns
          {...props}
          columnDefs={columns}
          overlayNoRowsTemplate="Nessun dato da mostrare!"
        />
      </GridWrapper>
    ),
    [columns, offset, props],
  );

  if (props.unboxed) return renderContent;

  return <Card>{renderContent}</Card>;
}
