import CheckIcon from '@mui/icons-material/Check';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Box, IconButton, ListItem, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../../../../../../form/fields/checkbox-field';
import { CurrencyField } from '../../../../../../../form/fields/currency-field';
import { DateField } from '../../../../../../../form/fields/date-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function NuovaIpotecaFormFields(props: { ipoteca_index: number }) {
  const { getValues } = useFormContext<any>();
  const ipoteca = getValues(`data.ipoteche_obj.${props.ipoteca_index}`);
  const [isFavore, setIsFavore] = useState(ipoteca.afavore || true);
  ipoteca.afavore = isFavore;

  const handleToggleExclude = useCallback(() => {
    if (ipoteca.ipoteca_esistente === 0) {
      ipoteca.afavore = !ipoteca.afavore;
      setIsFavore(ipoteca.afavore);
    }
  }, [ipoteca]);

  const renderToggleExcludeIcon = useMemo(() => {
    if (isFavore) {
      return <ToggleOffIcon color="success" sx={{ width: '30px', height: '30px' }} />;
    }

    return <ToggleOnIcon color="error" sx={{ width: '30px', height: '30px' }} />;
  }, [isFavore]);

  return (
    <ListItem key={`lotto_${props.ipoteca_index}`} sx={{ paddingLeft: 0, paddingRight: '10px' }}>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        {ipoteca.ipoteca_esistente === 0 && (
        <CheckboxField
          defaultValue="true"
          label=""
          name={`data.ipoteche_obj.${props.ipoteca_index}.crea`}
          sx={{ width: '100px', marginRight: '100px' }}
        />
        )}
        {ipoteca.ipoteca_esistente === 1 && (
        <Tooltip
          placement="top"
          title="Esistente"
          followCursor
        >
          <CheckIcon color="success" sx={{ marginTop: '10px' }} />
        </Tooltip>
        )}
        <Box minWidth={60}>
          <Typography sx={{
            fontSize: '12px',
            marginTop: '-6px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
          >A Favore
          </Typography>
          <Tooltip
            placement="top"
            title={`${isFavore ? 'SI' : 'NO'}`}
            followCursor
          >
            <IconButton sx={{ marginTop: '-10px' }} onClick={handleToggleExclude}>
              {renderToggleExcludeIcon}
            </IconButton>
          </Tooltip>
        </Box>
        <TextField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Natura"
          name={`data.ipoteche_obj.${props.ipoteca_index}.natura`}
          size="small"
          required
        />
        <DateField
          DatePickerProps={{
            label: 'Data Iscrizione',
            disabled: ipoteca.ipoteca_esistente === 1,
          }}
          name={`data.ipoteche_obj.${props.ipoteca_index}.data_iscrizione`}
          size="small"
        />
        <TextField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Conservatoria"
          name={`data.ipoteche_obj.${props.ipoteca_index}.conservatoria`}
          size="small"
        />
        <TextField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Reg. Gen."
          name={`data.ipoteche_obj.${props.ipoteca_index}.registro_generale`}
          size="small"
          sx={{ width: '700px' }}
        />
        <TextField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Reg. Par."
          name={`data.ipoteche_obj.${props.ipoteca_index}.registro_particolare`}
          size="small"
          sx={{ width: '700px' }}
        />
        <CurrencyField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Capitale"
          name={`data.ipoteche_obj.${props.ipoteca_index}.capitale`}
          size="small"
        />
        <CurrencyField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Montante"
          name={`data.ipoteche_obj.${props.ipoteca_index}.montante`}
          size="small"
        />
        {/* <TextField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Contro"
          name={`data.ipoteche_obj.${props.ipoteca_index}.soggetto_contro`}
          size="small"
        /> */}
        <TextField
          disabled={ipoteca.ipoteca_esistente === 1}
          label="Favore"
          name={`data.ipoteche_obj.${props.ipoteca_index}.soggetto_a_favore`}
          size="small"
        />
      </Box>
    </ListItem>
  );
}
