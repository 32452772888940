import CheckIcon from '@mui/icons-material/Check';
import { Box, ListItem, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CheckboxField } from '../../../../../../../form/fields/checkbox-field';
import { TextField } from '../../../../../../../form/fields/text-field';

export function NuovoAssetFormFields(props: { asset_index: number }) {
  const { getValues } = useFormContext<any>();
  const asset = getValues(`data.asset_obj.${props.asset_index}`);

  return (
    <ListItem key={`asset_${props.asset_index}`} sx={{ paddingLeft: 0, paddingRight: '10px' }}>
      <Box display="flex" gap={1} justifyContent="space-evenly" width="100%">
        <Box display="flex" width="20px">
          {asset.asset_esistente === 0 && (
          <CheckboxField
            defaultValue="true"
            label=""
            name={`data.asset_obj.${props.asset_index}.crea`}
          />
          )}
          {asset.asset_esistente === 1 && (
          <Tooltip
            title="Esistente"
            followCursor
          >
            <CheckIcon color="success" sx={{ marginTop: '10px' }} />
          </Tooltip>
          )}
        </Box>
        <TextField
          disabled={asset.asset_esistente === 1}
          label="Comune"
          name={`data.asset_obj.${props.asset_index}.comune`}
          size="small"
          required
        />
        <TextField
          disabled={asset.asset_esistente === 1}
          label="Indirizzo"
          name={`data.asset_obj.${props.asset_index}.indirizzo`}
          size="small"
        />
        <TextField
          disabled={asset.asset_esistente === 1}
          label="Foglio"
          name={`data.asset_obj.${props.asset_index}.foglio`}
          size="small"
          sx={{ width: '300px' }}
          required
        />
        <TextField
          disabled={asset.asset_esistente === 1}
          label="Mappale"
          name={`data.asset_obj.${props.asset_index}.mappale`}
          size="small"
          sx={{ width: '300px' }}
          required
        />
        <TextField
          disabled={asset.asset_esistente === 1}
          label="Subalterno"
          name={`data.asset_obj.${props.asset_index}.subalterno`}
          size="small"
          sx={{ width: '300px' }}
        />
        <TextField
          disabled={asset.asset_esistente === 1}
          label="Natura"
          name={`data.asset_obj.${props.asset_index}.natura`}
          size="small"
          sx={{ width: '300px' }}
        />
        <TextField
          disabled={asset.asset_esistente === 1}
          label="Consistenza"
          name={`data.asset_obj.${props.asset_index}.consistenza`}
          size="small"
          sx={{ width: '300px' }}
        />
      </Box>
    </ListItem>
  );
}
