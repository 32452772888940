import { Form } from '../../../../../form';
import { CheckboxField } from '../../../../../form/fields/checkbox-field';

export function TitoloAFavore(props: {onCheckValue: any}) {
  return (
    <Form onSubmit={() => null}>
      <CheckboxField label="A Sfavore" name="asfavore" onClick={(event: any) => props.onCheckValue(event.target.value)} />
    </Form>
  );
}
