import { styled, Box } from '@mui/material';

export const GridWrapper = styled(Box)`
  .ag-header {
    background-color: ${({ theme }) => theme?.palette?.background?.light};
  }

  .ag-root-wrapper-body {
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Helvetica Neue', Arial, sans-serif;
  }
  .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: 42px;
  }

  .ag-root-wrapper {
    border: none;
    border-radius: ${({ theme }) => theme?.shape?.borderRadius}px;
  }

  .ag-invalid {
    box-shadow: 0 0 0 1px inset #e34f2a;
  }

  .ag-cell-wrapper {
    height: 100%;
  }

  .height150 .ag-body-viewport{
     overflow-y: auto;
     height: 150px;
     padding-bottom: 50px;
  }

  .height300 .ag-body-viewport{
     overflow-y: auto;
     max-height: 300px;
     padding-bottom: 50px;
  }

  .height300NoPadding .ag-body-viewport{
     overflow-y: auto;
     max-height: 300px;
  }

  .noOverflowX .ag-center-cols-viewport{
    overflow-x: hidden;
  }
`;
