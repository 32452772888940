import { Form } from '../../../../../form';
import { CheckboxField } from '../../../../../form/fields/checkbox-field';

export function TitoloAFavoreSave(props: {onCheckValue: any, asfavore: number | boolean}) {
  return (
    <Form onSubmit={() => null}>
      <CheckboxField
        defaultValue={props.asfavore ? 'true' : 'false'}
        disabled={props.asfavore !== null}
        label="A Sfavore"
        name="asfavore"
        onClick={(event: any) => props.onCheckValue(event.target.value)}
      />
    </Form>
  );
}
