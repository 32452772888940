import { Box, List } from '@mui/material';

import type { LottoAsset } from '../../../../../../../../types/fascicolo-file';

import { NuovoAssetFormFields } from './nuovo-asset';

export function AssetTab(props: { lotti: LottoAsset[]}) {
  return (
    <Box sx={{ maxHeight: '300px' }}>
      <List sx={{ maxHeight: '300px', overflowY: 'auto' }} dense disablePadding>
        <Box pt={2}>
          {props.lotti.map((asset, assetIndex) => (
            <NuovoAssetFormFields asset_index={assetIndex} />
          ))}
        </Box>
      </List>
    </Box>
  );
}
