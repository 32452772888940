import _currency from 'currency.js';

export function currency(value: number | string) {
  // eslint-disable-next-line no-param-reassign
  if (value === null) value = '0.0';

  // eslint-disable-next-line consistent-return
  return _currency(value, {
    symbol: '€',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  }).format();
}

export function currencyNoSymbol(value: number | string) {
  // eslint-disable-next-line no-param-reassign
  if (value === null) value = '0.0';

  return _currency(value, {
    symbol: '',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  }).format();
}

export function toNumber(value: number | string) {
  // eslint-disable-next-line no-param-reassign
  if (value === null) value = '0.0';

  return _currency(value, {
    symbol: '€',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  });
}
