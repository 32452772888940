import { Alert, Button, Card } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from '../../../common/components/form';
import { TextField } from '../../../common/components/form/fields/text-field';
import { LogoFull } from '../../../common/components/logofull';
import { useAuthContext } from '../../../common/contexts/auth-context';

type LoginProps = {
  isError: boolean;
  isLoading: boolean;
};

export function Login() {
  const { createToken, isLoading, isError } = useAuthContext();
  const handleLogin = useCallback(
    (values: { username: string; password: string }) => {
      if (values.username.length > 0 && values.password.length > 0)createToken(values);
    },
    [createToken],
  );

  return (
    <Card sx={{ padding: '12px 32px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
      <Form
        options={{
          mode: 'onChange',
        }}
        onSubmit={handleLogin}
      >
        <LoginForm isError={isError} isLoading={isLoading} />
      </Form>
    </Card>
  );
}

function LoginForm(props: LoginProps) {
  const navigate = useNavigate();

  // Diego: validate Form -> <Button disabled={!isValid || props.isLoading}>
  // const {
  //   formState: { isValid },
  // } = useFormContext();

  const handleGoToResetPassword = useCallback(() => {
    navigate('/recuperapassword');
  }, [navigate]);

  const handleGoToResetUsername = useCallback(() => {
    navigate('/recuperausername');
  }, [navigate]);

  return (
    <Box display="flex" flexDirection="column" gap={2} width={350}>
      <LogoFull style={{ margin: '0 auto' }} inverted />
      {/* <Typography align="center" fontWeight={900} variant="h2">
        Login
      </Typography> */}
      <Divider sx={{ my: 2, marginTop: 0 }} />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        active_pattern="false"
        label="Username"
        name="username"
        required
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        active_pattern="false"
        label="Password"
        name="password"
        type="password"
        required
      />
      <Divider sx={{ my: 2 }} />
      <Button
        disabled={props.isLoading}
        type="submit"
        variant="outlined"
      >
        Login
      </Button>
      <Box display="flex" justifyContent="space-evenly">
        <Button
          disabled={props.isLoading}
          variant="text"
          onClick={handleGoToResetPassword}
        >
          Recupera password
        </Button>
        <Button
          disabled={props.isLoading}
          variant="text"
          onClick={handleGoToResetUsername}
        >
          Recupera username
        </Button>
      </Box>
      {props.isError && (
        <Alert severity="error">Invalid username or password</Alert>
      )}
    </Box>
  );
}
