/* eslint-disable no-console */
import createCache from '@emotion/cache';
import { CacheProvider, Global } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider, itIT } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Worker } from '@react-pdf-viewer/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ChatAI } from './common/components/chat-ai';
import { ErrorBoundary } from './common/components/error-boundary';
import { AuthContextProvider } from './common/contexts/auth-context';
import { ConfirmPromptContextProvider } from './common/contexts/confirm-prompt-context';
import { NotificationContextProvider } from './common/contexts/notification-context';
import { iconsCSS } from './globals/icons';
import { Router } from './router';
import { theme } from './theme';
import version from './version';

const cache = createCache({
  key: 'css',
  nonce: '1234',
  prepend: true,
});

export const queryClient = new QueryClient();
if (process.env.NODE_ENV !== 'development' && !localStorage.getItem('debug')) {
  console.error = () => {};
  console.warn = () => {};
}

function App() {
  console.groupCollapsed(`%cVer: ${version}`, 'background-color: #aa2d2d');
  console.log('ENV: ', process.env.NODE_ENV);
  // console.log('URL APP: ', process.env.REACT_APP_API_URL);
  // console.log('URL SENTRY: ', process.env.REACT_APP_SENTRY_DSN);
  console.groupEnd();
  document.body.style.overflowX = 'hidden';

  return (
    <CacheProvider value={cache}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs} localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <ThemeProvider theme={theme}>
                <Global styles="@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,600;0,900;1,100;1,400;1,600;1,900&display=swap');" />
                <Global styles={iconsCSS} />
                <CssBaseline />
                <ToastContainer />
                <AuthContextProvider>
                  <ErrorBoundary sx={{ m: 3 }}>
                    <NotificationContextProvider>
                      <ConfirmPromptContextProvider>
                        <Router />
                        <ChatAI />
                      </ConfirmPromptContextProvider>
                    </NotificationContextProvider>
                  </ErrorBoundary>
                </AuthContextProvider>
              </ThemeProvider>
            </Worker>
          </LocalizationProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CacheProvider>
  );
}

export default App;
