export type FascicoloFile = {
  id: number;
  descrizione: string;
  tipologiadocumento?: string;
  fascicolofilecategoria: string;
  link: string;
  fascicolofilestato?: string;
  can_validate?: number;
  show_validate?: number;
  tipologiacorretta?: number | null
  tipologiadocumentorilevata?: number | null
  tipologiacustom?: string | null
  fascicoloFileElaborazione_id?: number | null
};

export type CorrezioneDocumento = {
  id: number;
  tipologiadocumento?: string;
  tipologiacorretta: number | null
  tipologiadocumentorilevata?: number | null
  fascicoloFileElaborazione_id?: number | null
  tipologiacustom?: string | null
};

export type FileElaborato = {
  categoria: number;
  data: FileData[];
  titolo?: TitioloData;
  link_titolo?: string;
  link_azioneesecutiva?: string;
  azioneesecutiva?: AzioneEsecutivaData;
  garanziapersonale?: GaranziaData;
  link_garanziapersonale?: string;
  altraprocedura?: AltraProceduraData;
  link_altraprocedura?: string;
  soggetti_nuovi?: FileSoggetto[];
  fideiussioni?: Fideiussioni;
  lotto_obj: Lotto[];
  asset_obj: LottoAsset[];
  soggetti_obj: Lotto[];
  ipoteche_obj: Ipoteche[];
  titoli_obj: Titoli[];
  titolo_obj: Titolo[];
  rielabora?: number;
  asfavore?: boolean;
};

export interface Titolo {
  tipologia: string
  tipologia_id: number
  titolo_esistente: number
  titolo_id: any
  numero_repertorio: string
  numero_raccolta: string
  data_stipula: string
}

export interface AzioneEsecutivaData {
  registrogenerale: number
  anno: number
  autorita: number
  ufficio: number
  data_asta: any
  procedimento: number
  documento_id: string
}

export type Fideiussioni = {
  title: string;
  header: HeaderElement[];
  array: ArrayElement[];
}

export type ArrayElement = {
  name: string;
  type: string;
}

export type HeaderElement = {
  name: string;
  type: string;
}

export type FascicoloFileFilter = {
  fascicolo?: string;
  azioneordinaria?: string;
  titolo?: string;
  linea?: string;
  soggetto?: string;
  altraprocedura?: string;
  azioneesecutiva?: string;
  asset?: string;
  garanziareale?: string;
  garanziapersonale?: string;
};

export type FascicoloFilePost = {
  filesDetails?: FilesDetails[];
  sorgente: string | null;
  sorgente_id?: number | null;
};

export type FilesDetails = {
  filename: string;
  file: File;
  descrizione: string;
  fascicolofilecategoria?: string;
};

export type TitioloData = {
  anno: string;
  autorita: string;
  data_ultimo_atto_interruttivo: null;
  importo: string;
  natura_titolo: number;
  numero: string;
  asfavore?: boolean;
};

export type GaranziaData = {
  data: string;
  istituto_credito: string;
  importo_fideiussione: number;
  garanziapersonale_tipologia: number;
  documento_id: string;
}

export type AltraProceduraData = {
  numero: string | null
  anno: string | null
  autorita: number
  ufficio: number
  data_udienza: string | null
  curatore: string | null
  soggetto_creditore_terzo?: boolean;
}

export type FileData = {
  titolo: string;
  valore: string;
  highlight?: boolean;
};

export type FileSoggetto = {
  nome: string;
  codicefiscale: string;
  partitaiva: string;
  importo?: number;
  data_nascita?: string;
  luogo_nascita?: string;
  soggetto_esistente?: number;
  tipologia?: string;
};

export interface Titoli {
  tipologia: string
  tribunale: string
  numero: number
  importo: number
  spese: number
  interessi: number
  compensi_liquidati: number
  ufficio_id: number
  anno: number
  titolo_esistente: number
  titolo_id: any
}
export interface Ipoteche {
  natura: string;
  data_iscrizione: string;
  conservatoria: string;
  registro_generale: string;
  registro_particolare: string;
  capitale: string;
  montante: string;
  soggetto_contro: string;
  soggetto_a_favore: string;
}

export interface Lotto {
  numero: number
  valore: number
  lotto_esistente: number
  lotto_id: any
  asset: LottoAsset[]
}

export interface LottoAsset {
  crea?: boolean
  comune: string
  provincia: string
  foglio: string
  mappale: string
  subalterno: string
  indirizzo: string
  comune_id: number
  provincia_id: number
  tipologia: string
  natura: string
  id_categoria_catastale: number
  consistenza: number
  asset_esistente: number
  asset_id?: number
}

export enum Categoria {
  soggetti = 1, /* decreto ingiuntivo */
  precetti = 2,
  avviso_vendita = 3,
  avviso_creditori = 11,
  ctu = 16,
  certificazione_notarile = 17,
  garanti = 22,
  istanza_vendita = 23,
  mutuo_fondiario = 26,
  ordinanza_assegnazione = 32,
  istanza_ammissione_passivo = 48,
  sentenza_fallimento = 51,
  decreto_trasferimento = 88,
}
