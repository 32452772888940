import { Box } from '@mui/material';
import { useMemo } from 'react';

import type { FascicoloFile } from '../../../../types/fascicolo-file';

import { Delete } from './delete';
import { Download } from './download';
import { Elaborate } from './elaborate';
import { Modify } from './modify';
import { Preview } from './preview';
import { FileActionsCSS } from './styles';

const allowedForReview = ['png', 'jpg', 'jpeg', 'pdf'];

export type FileActionsProps = {
  file: FascicoloFile;
  previewProps?: { disablePortal?: boolean };
  modify?: boolean;
  preview?: boolean;
  delete?: boolean;
  elaborate?: boolean;
  download?: boolean;
};

export function FileActions(props: FileActionsProps) {
  const canPreview = useMemo(() => {
    const fileType = props.file.link?.split('.').pop();

    return fileType && allowedForReview.includes(fileType.toLowerCase());
  }, [props.file.link]);

  const previewAction = useMemo(() => {
    if (!canPreview || !props.preview) return null;

    return (
      <Preview
        disablePortal={props.previewProps?.disablePortal}
        fileid={props.file.id || null}
        link={props.file.link}
      />
    );
  }, [
    canPreview,
    props.file,
    props.preview,
    props.previewProps?.disablePortal,
  ]);

  const modifyAction = useMemo(() => {
    if (!props.modify) return null;

    return <Modify file={props.file} />;
  }, [props.modify, props.file]);

  const downloadAction = useMemo(() => {
    if (!props.download) return null;

    return <Download file={props.file} />;
  }, [props.file, props.download]);

  const elaborateAction = useMemo(() => {
    if (!props.elaborate) return null;

    return <Elaborate file={props.file} />;
  }, [props.file, props.elaborate]);

  const deleteAction = useMemo(() => {
    if (!props.delete) return null;

    return <Delete file={props.file} />;
  }, [props.file, props.delete]);

  return (
    <Box
      sx={props.previewProps?.disablePortal ? FileActionsCSS : undefined}
      textAlign="right"
    >
      {downloadAction}
      {previewAction}
      {elaborateAction}
      {deleteAction}
      {modifyAction}
    </Box>
  );
}
