import { Alert, AlertTitle, Box, Divider, Typography } from '@mui/material';

import type {
  FileData,
  FileElaborato,
} from '../../../../../../../types/fascicolo-file';
import { currency } from '../../../../../../../utils/currency';

export function ElaboratoContent(props: { file: FileElaborato }) {
  return (
    <Box>
      {props?.file?.data?.map((item, idx) => {
        if (item.highlight) {
          // eslint-disable-next-line react/no-array-index-key
          return <HighlightElaboratoDataItem key={`high_${idx}`} item={item} />;
        }

        // eslint-disable-next-line react/no-array-index-key
        return <StandardElaboratoDataItem key={`standard_${idx}`} item={item} />;
      })}
    </Box>
  );
}

function StandardElaboratoDataItem(props: { item: FileData }) {
  if (Object.keys(props.item).length === 0) return null;

  return (
    <Box key={props.item.titolo}>
      <Box alignItems="center" display="flex" gap={1} my={1}>
        <Typography>
          <b>{props.item.titolo}:</b>
        </Typography>
        <Typography>{toCurrency(props.item.valore)}</Typography>
      </Box>
      <Divider />
    </Box>
  );
}

function HighlightElaboratoDataItem(props: { item: FileData }) {
  return (
    <>
      <Alert icon={false} severity="info" sx={{ my: 1 }}>
        <AlertTitle>
          <b>{props.item.titolo}:</b>
        </AlertTitle>
        {toCurrency(props.item.valore)}
      </Alert>
      <Divider />
    </>
  );
}

function toCurrency(value: string) {
  if (value.includes('€')) {
    const newValue = value.replace(/€/g, '');

    return currency(Number(newValue));
  }

  return value;
}
