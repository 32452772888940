import { Box, FormHelperText, styled } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';

export const BoxWrapper = styled(Box)`
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 6px;

  &:hover {
    > fieldset {
      border-color: rgba(0, 0, 0, 0.87);
    }
  }
`;
export const Label = styled('label')`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, 16px) scale(1);
  -moz-transform: translate(14px, 16px) scale(1);
  -ms-transform: translate(14px, 16px) scale(1);
  transform: translate(14px, 16px) scale(1);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
`;
export const Input = styled(CurrencyInput)`
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;

  + .labelFix{
    top: -8px;
  }

  ::placeholder {
    opacity: 0;
    color: rgba(0, 0, 0, 0.38);
  }

  :not(:placeholder-shown) {
    ~ fieldset > legend {
      max-width: 100%;
    }

    + label {
      position: absolute;
      max-width: calc(133% - 32px);
      -webkit-transform: translate(14px, -9px) scale(0.75);
      -moz-transform: translate(14px, -9px) scale(0.75);
      -ms-transform: translate(14px, -9px) scale(0.75);
      transform: translate(14px, -9px) scale(0.75);
      -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      pointer-events: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    + .labelFix{
      top: 0px;
    }
  }

  &:disabled {
    ~ fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
    //
    //~ fieldset > legend {
    //  max-width: 100%;
    //}

    + label {
      color: rgba(0, 0, 0, 0.38);
    }

    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }

  &:focus {
    outline: 0;

    ::placeholder {
      opacity: 1;
    }

    ~ fieldset > legend {
      max-width: 100%;
    }

    + label {
      position: absolute;
      max-width: calc(133% - 32px);
      -webkit-transform: translate(14px, -9px) scale(0.75);
      -moz-transform: translate(14px, -9px) scale(0.75);
      -ms-transform: translate(14px, -9px) scale(0.75);
      transform: translate(14px, -9px) scale(0.75);
      -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      pointer-events: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    + .labelFix{
      top: 0px;
    }
  }
`;
export const Fieldset = styled('fieldset')`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0;
  border-color: rgba(0, 0, 0, 0.23);
`;
export const Legend = styled('legend')`
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.9em;
  visibility: hidden;
  max-width: 0;
`;
export const ErrorMessage = styled(FormHelperText)`
  color: #d32f2f;
  margin: 3px 14px 0;
`;
